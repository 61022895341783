import { Button } from 'antd'
import Icon from '@ant-design/icons'

import { A8MainnetIcon } from '@/assets/images/icon/A8MainnetIcon'

import './index.scss'

type A8MainnetButtonProps = {
  className?: string
  label: string
}

const A8MainnetButton = ({ className = '', label }: A8MainnetButtonProps) => {
  return (
    <Button
      type="text"
      icon={<Icon component={A8MainnetIcon} />}
      className={`a8-mainnet--btn flex items-center ${className}`}
    >
      {label}
    </Button>
  )
}

export default A8MainnetButton
