import { ListData } from '@/types/common.type'

import { missionApi as api } from '@/services/base-axios/missionApi'

import {
  CommunityLeaderboardData,
  FetchSeasonDto,
  FindCommunityLeaderBoardDto,
  FindTeamLeaderBoardDto,
  ListSeasonDto,
  SeasonData,
  TeamLeaderboardData,
} from '@/types/leaderboard.type'

const CONTROLLER = 'leaderboard'

class Leaderboard {
  async getTeams(dto?: FindTeamLeaderBoardDto) {
    return api
      .get<ListData<TeamLeaderboardData[]>>(`${CONTROLLER}/team`, {
        params: {
          ...dto,
        },
      })
      .then((data) => data.data)
  }

  async getCommunities(dto: FindCommunityLeaderBoardDto) {
    return api
      .get<CommunityLeaderboardData[]>(`${CONTROLLER}/community`, {
        params: dto,
      })
      .then((data) => data.data)
  }

  async getCurrentSeason() {
    return api.get<SeasonData>('/season/latest').then((data) => data.data)
  }

  async getSeasons(dto: ListSeasonDto) {
    return api
      .get<ListData<SeasonData[]>>('/season', { params: dto })
      .then((data) => data.data)
  }

  async getSeason(dto: FetchSeasonDto) {
    return api
      .get<SeasonData>('/season', { params: { ...dto } })
      .then((data) => data.data)
  }
}

export const LeaderboardService = new Leaderboard()
