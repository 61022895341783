// next
import Link from 'next/link'
// react
import { CSSProperties } from 'react'
import clsx from 'clsx'
// Antd
import { Image } from 'antd'
// image
import BrandLogo from '@/assets/images/logo/brand.svg'
// constants
import { ROUTES } from '@/constants/routes'

type BrandProps = {
  style?: CSSProperties
  disableNav?: boolean
  className?: string
}

function Brand({ style, disableNav = false, className }: BrandProps) {
  return (
    <Link
      href={{ pathname: ROUTES.MISSION.INDEX }}
      aria-disabled={disableNav}
      tabIndex={disableNav ? -1 : undefined}
      className={clsx(disableNav && 'pointer-events-none', className)}
    >
      <Image preview={false} src={BrandLogo.src} alt="brand" style={style} />
    </Link>
  )
}

export default Brand
