import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

import { SeasonData, SeasonStage } from '@/types/leaderboard.type'

export interface LeaderboardSeasonStore {
  seasons: Record<string, SeasonData>
  currentSeasonSlug: string
  setSeasons: (seasons: SeasonData[]) => void
}

const initialState = {
  seasons: {},
  currentSeasonSlug: '',
}

export const useLeaderboardSeasonStore = create<LeaderboardSeasonStore>()(
  devtools(
    (set) => ({
      ...initialState,
      setSeasons: (seasons) => {
        const _season: Record<string, SeasonData> = {}
        let _currentSeasonSlug = ''

        seasons.forEach((season) => {
          _season[season.slug] = season
          if (season.stage === SeasonStage.Live)
            _currentSeasonSlug = season.slug
        })

        set((state) => ({
          ...state,
          currentSeasonSlug: _currentSeasonSlug,
          seasons: _season,
        }))
      },
    }),
    {
      name: 'leaderboardSeason',
    },
  ),
)
