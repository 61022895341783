import { ChainID, ChainLabel } from './index'

export enum MarketplaceTabs {
  All = 'All',
  NFT = 'NFT',
  Gacha = 'Gacha',
}

export enum Sort {
  Latest = 'SORT::LATEST',
  Oldest = 'SORT::OLDEST',
}

export enum MarketItemType {
  Digital = 'Digital',
  Physical = 'Physical',
}

export enum Category {
  All = 'All',
  NFT = 'NFT',
  Real = 'Real',
}

export enum PrioritySort {
  Newest = 'Newest',
  MostPopular = 'Popular',
}

export enum SortOptions {
  SortAZ = 'sort-a-z',
  SortZA = 'sort-z-a',
  PriceHighLow = 'price-high-low',
  PriceLowHigh = 'price-low-high',
}
export const SORT_OPTIONS = [
  { value: PrioritySort.Newest, label: PrioritySort.Newest },
  { value: PrioritySort.MostPopular, label: 'Top Trending' },
]

export const CHAINS_FILTER_OPTIONS = [
  { value: ChainID.A8, label: ChainLabel[ChainID.A8] },
  { value: ChainID.Arbitrum, label: ChainLabel[ChainID.Arbitrum] },
  { value: ChainID.BSC, label: ChainLabel[ChainID.BSC] },
  { value: ChainID.Ethereum, label: ChainLabel[ChainID.Ethereum] },
  { value: ChainID.Polygon, label: ChainLabel[ChainID.Polygon] },
  { value: ChainID.Optimism, label: ChainLabel[ChainID.Optimism] },
  { value: ChainID.Base, label: ChainLabel[ChainID.Base] },
  { value: ChainID.Ronin, label: ChainLabel[ChainID.Ronin] },
  { value: ChainID.Etherlink, label: ChainLabel[ChainID.Etherlink] },
]

export enum MarketItemCreateType {
  Physical = 'Add Physical Item',
  Digital = 'Add Digital Item',
}

export enum MarketItemCreateRoute {
  Physical = 'new-physical',
  Digital = 'new-digital',
}

export enum MarketItemState {
  Publish = 'Publish',
  Unpublish = 'Unpublish',
  Ended = 'Ended',
}

export enum TableMarketActions {
  Edit = 'Edit',
  Delete = 'Delete',
  Publish = 'Publish',
}

export enum Currency {
  SOL = 'sol',
  USDT = 'usdt',
  USDC = 'usdc',
}

export enum PhysicalShippingStatus {
  All = 'Show All',
  Unpaid = 'Unpaid',
  ToShip = 'To Ship',
  Shipping = 'Shipping',
  Cancellation = 'Cancellation',
  Completed = 'Completed',
}

export enum DigitalShippingStatus {
  All = 'Show All',
  Completed = 'Completed',
  Fail = 'Fail',
  InProgress = 'In Progress',
}

/** Order */
export enum OrderState {
  Unpaid = 'Unpaid',
  Cancellation = 'Cancellation',
  Completed = 'Completed',
  Shipping = 'Shipping',
  ToShip = 'To Ship',
}
