import { Data } from './common.type'

// Interface
export interface ITeamLeaderboard {
  name: string

  point: number
}

export interface ICommunityLeaderboard {
  communityId: string

  point: number

  community: CommunityBasicInfo
}

export type CommunityBasicInfo = {
  title: string

  logo: string
}

export interface ISeason {
  name: string

  startDate?: Date

  endDate?: Date

  stage: SeasonStage

  teamLeaderboardEnabled: boolean

  communityLeaderboardEnabled: boolean

  slug: string
}

// TYPE

export type SeasonData = Data<ISeason>
export type TeamLeaderboardData = Data<ITeamLeaderboard>
export type CommunityLeaderboardData = Data<ICommunityLeaderboard>

export enum SeasonStage {
  InComing = 'SEASON_STAGE:IN_COMING',
  Live = 'SEASON_STAGE:LIVE',
  Archived = 'SEASON_STAGE:ARCHIVED',
}

export enum CommunityLeaderboardSort {
  HighestPoint = 'COMMUNITY_LEADERBOARD_SORT:HIGHEST_POINT',
  Alphabet = 'COMMUNITY_LEADERBOARD_SORT:ALPHABET',
}

export enum TeamLeaderboardSort {
  HighestPoint = 'TEAM_LEADERBOARD_SORT:HIGHEST_POINT',
  Alphabet = 'TEAM_LEADERBOARD_SORT:ALPHABET',
}

// DTO

export type FetchSeasonDto = {
  seasonId: string
}

export type FindTeamLeaderBoardDto = {
  offset?: number

  limit?: number

  sort?: TeamLeaderboardSort

  search?: string

  seasonId?: string
}

export type FindCommunityLeaderBoardDto = {
  offset?: number

  limit?: number

  sort?: CommunityLeaderboardSort

  search?: string

  seasonId?: string
}

export type ListSeasonDto = {
  limit?: number

  offset?: number

  stages?: SeasonStage[]
}

export type LeaderboardTabType = {
  title: string
  subTitle: string
  tabTitle: string
  state: string
  cover: string
  _id: string
}

export type TeamLeaderboardDataV2 = TeamLeaderboardData & {
  rank: number
  logo: string
  lastRank: number
}
