export const A8MainnetIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={70}
    height={19}
    viewBox="0 0 103 19"
    fill="none"
  >
    <path
      d="M27.2242 18.2349H27.1842L21.3604 2.39258H17.6836V18.4527H19.7466L19.6655 2.61042H19.7056L25.5264 18.4527H29.1837V2.39258H27.1608L27.2223 18.2349H27.2242Z"
      fill="#AEE760"
    />
    <path
      d="M38.7025 4.04001C40.8865 4.04001 42.4408 5.60224 42.9094 8.24954H44.9509C44.4393 4.45183 42.1313 2.17383 38.6995 2.17383C34.5111 2.17383 32.041 5.23295 32.041 10.4217C32.041 15.6105 34.514 18.6696 38.6995 18.6696C42.1089 18.6696 44.4393 16.3491 44.9509 12.485H42.9094C42.4408 15.1977 40.908 16.8035 38.7025 16.8035C35.9043 16.8035 34.229 14.4363 34.229 10.4217C34.229 6.40722 35.9043 4.04001 38.7025 4.04001Z"
      fill="#AEE760"
    />
    <path
      d="M49.7338 2.39258H47.5898V18.4527H49.7338V2.39258Z"
      fill="#AEE760"
    />
    <path
      d="M55.5542 11.0948H62.7243V9.27223H55.5542V4.19237H63.438V2.39258H53.4102V18.4527H63.6635V16.6498H55.5542V11.0948Z"
      fill="#AEE760"
    />
    <path
      d="M75.8961 18.2349H75.8561L70.0362 2.39258H66.3594V18.4527H68.4223L68.3384 2.61042H68.3813L74.2012 18.4527H77.8556V2.39258H75.8356L75.8951 18.2349H75.8961Z"
      fill="#AEE760"
    />
    <path
      d="M80.0625 4.19237H84.6385V18.4527H86.7825V4.19237H91.3585V2.39258H80.0625V4.19237Z"
      fill="#AEE760"
    />
    <path
      d="M97.7477 10.5147C98.3803 10.329 98.9759 10.217 99.8907 9.84254C101.071 9.37573 102.523 8.35706 102.523 6.36018C102.523 3.94213 100.43 2.17969 97.5914 2.17969C94.7533 2.17969 92.66 3.94524 92.66 6.36018V6.40893C92.6923 9.21391 96.0371 9.2305 97.3493 9.13299C96.7137 9.33942 96.0244 9.54482 95.3156 9.8083C94.0718 10.272 92.1836 11.5427 92.1836 13.9317C92.1836 16.7875 94.6322 18.6734 97.5914 18.6734C100.551 18.6734 102.999 17.0303 102.999 14.1745C102.999 10.7295 99.0774 10.4919 97.7496 10.5168L97.7477 10.5137V10.5147ZM95.9532 8.33943C95.9532 8.33943 95.9424 8.33113 95.9395 8.32802C95.3762 7.87573 95.0423 7.19731 95.0423 6.45043C95.0423 5.09981 96.0391 4.0552 97.5905 4.0552C99.1418 4.0552 100.16 5.09981 100.16 6.45043C100.16 6.94835 100.007 7.41723 99.7345 7.80934C99.506 8.13818 99.1877 8.37262 98.8489 8.54482C98.8187 8.55934 98.7894 8.57594 98.7572 8.59046C97.8443 9.03445 96.7752 8.96806 95.9532 8.33839V8.33943ZM97.5905 16.7844C95.8692 16.7844 94.5658 15.4857 94.5658 13.7907C94.5658 12.689 95.1018 11.8021 95.9366 11.3042C95.9903 11.273 96.044 11.244 96.0957 11.216C97.0789 10.6693 98.2339 10.6776 99.1975 11.2616C99.2385 11.2876 99.26 11.3021 99.26 11.3021C100.101 11.8 100.637 12.69 100.637 13.7917C100.637 15.4888 99.3303 16.7855 97.5895 16.7855L97.5905 16.7844Z"
      fill="#AEE760"
    />
    <path
      d="M15.6397 12.5898H0.00195312V14.4125H15.6397V12.5898Z"
      fill="#AEE760"
    />
    <path
      d="M2.64779 17.0219L2.14107 18.4524H0L1.50061 14.4141L1.92043 14.7004C2.64779 15.1952 2.94947 16.163 2.64486 17.0188L2.64779 17.0219Z"
      fill="#AEE760"
    />
    <path
      d="M15.6416 18.4532H13.4165L12.9186 17.0736C12.6003 16.1918 12.9235 15.196 13.686 14.7126L14.1488 14.418L15.6406 18.4563V18.4532H15.6416Z"
      fill="#AEE760"
    />
    <path
      d="M14.1473 14.4149H11.9789L11.3296 12.5923L7.78361 2.60995H7.74846L4.21418 12.5913L3.57078 14.4139H1.50195L2.17757 12.5913L5.96765 2.39211H7.70453L6.85708 0H8.82632L13.4746 12.5913L14.1483 14.4139L14.1473 14.4149Z"
      fill="#AEE760"
    />
  </svg>
)
